/* eslint-disable react/jsx-boolean-value */
import React, { useMemo } from 'react'

// Components
import { Grid } from '@material-ui/core'
import { Modal, Button, Loader } from '@refera/ui-web'
import SelectField from '../../../components/SelectField'
import useStyles from '../styles'
import { useSelector } from 'react-redux'
import { getPayerOptionsSelector } from '_/modules/budget/selectors'
import { useForm } from 'react-hook-form'

export default ({ open, close, onConfirm, isLoading }) => {
  const styles = useStyles()
  const options = useSelector(getPayerOptionsSelector)

  const PAYMENT_OPTIONS = options?.paymentMethod
  const INSTALLMENT_OPTIONS = options?.installmentOptions
  const INSTALLMENT_OPTIONS_WITHOUT_INTEREST = options?.installmentOptionsWithoutInterest

  const { watch, control } = useForm({
    mode: 'all',
  })

  const formData = watch()

  const installmentSelectOptions = useMemo(() => {
    return formData.formOfPayment === 'realestate_installment'
      ? INSTALLMENT_OPTIONS_WITHOUT_INTEREST
      : INSTALLMENT_OPTIONS
  }, [formData.formOfPayment, INSTALLMENT_OPTIONS_WITHOUT_INTEREST, INSTALLMENT_OPTIONS])

  const isSubmitDisabled = useMemo(() => {
    return !formData.formOfPayment || !formData.installmentQuantity || isLoading
  }, [formData, isLoading])

  return (
    <>
      <Loader hasBackdrop open={isLoading} />
      <Modal
        className={styles.modal}
        title="Parcelamento"
        onBackdropClick={close}
        onClose={close}
        open={open}
        actionsButtons={
          <Grid className={styles.modalButtons}>
            <Button color="red" variant="secondary" onClick={close}>
              Cancelar
            </Button>
            <Button
              disabled={isSubmitDisabled}
              color="primary"
              variant="primary"
              onClick={() => onConfirm({ ...formData })}
            >
              Salvar
            </Button>
          </Grid>
        }
      >
        <Grid className={styles.wrapper}>
          <SelectField
            label="Forma de pagamento"
            name="formOfPayment"
            defaultValue=""
            items={PAYMENT_OPTIONS}
            emptyItem
            control={control}
            required={true}
            defaultSelectStyle={false}
          />
          <SelectField
            emptyItem
            name="installmentQuantity"
            label="Parcelamento desejado"
            defaultValue=""
            control={control}
            items={installmentSelectOptions}
            required={true}
            defaultSelectStyle={false}
          />
        </Grid>
      </Modal>
    </>
  )
}
