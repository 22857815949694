/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Paper,
  Grid,
  Slide,
  Typography,
  FormGroup,
} from '@material-ui/core'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'

import { API_URL } from '_config/environment'
import { getToken, camelize } from '_utils/token'

import Theme from '@refera/ui-core'
import { Button, useConfirm } from '@refera/ui-web'
import { TickCircle as CheckIcon, CloseCircle as CloseIcon } from '@refera/ui-icons'

import useStyles from './styles'
import { formatDateTime, formatter } from './utils'
import moment from 'moment'
import {
  // deleteTradesmanAnticipation,
  getVerifyUpdateAnticipations,
  updatePaymentAnticipations,
} from '_/modules/finance/actions'
import ConfirmRemoveDialog from '_/components/dialogs/ConfirmRemoveDialog'
import { useDispatch } from 'react-redux'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const PaymentAdvanceApproval = ({ paymentAdvanceItem, closeModal, setSuccessSnackbar }) => {
  const styles = useStyles()
  const paymentID = paymentAdvanceItem?.id
  const dispatch = useDispatch()
  const [paymentOrder, setPaymentOrder] = useState()
  const [dialogMessage, setDialogMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const getPayment = async () => {
    setIsLoading(true)
    const header = getToken()
    return axios.get(`${API_URL}/payment_anticipation/${paymentID}/`, header).then(res => {
      setPaymentOrder(camelize(res?.data))
      setIsLoading(false)
    })
  }

  const fetchVerificationUpdateAnticipation = useCallback(async () => {
    setIsLoading(true)
    let updateAnticipationInfo = {}

    await dispatch(getVerifyUpdateAnticipations(paymentID))
      .then(res => {
        updateAnticipationInfo = res
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
    return updateAnticipationInfo
  }, [paymentID, dispatch])

  const { isConfirmed } = useConfirm()

  const handleUpdateAnticipation = async (buttonAction, recreate) => {
    setIsLoading(true)

    const payload = {
      id: paymentID,
      status: buttonAction,
      recreatePaymentInstallment: recreate,
    }

    await dispatch(updatePaymentAnticipations(payload))
      .then(() => {
        getPayment()
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false))
  }

  const handleButtonClick = async buttonAction => {
    setDialogMessage('Você tem certeza que deseja executar esta ação?')
    const confirmed = await isConfirmed()

    if (!confirmed) return
    let recreate = false

    if (buttonAction === 'cancel_reproval') {
      const updateAnticipationInfo = await fetchVerificationUpdateAnticipation()

      if (updateAnticipationInfo?.hasOverduePayment) {
        setDialogMessage(
          'A data prevista de pagamento já passou. Você confirma que deseja recalcular o juros para a próxima data de antecipação?'
        )
        const confirmedCancelRepproval = await isConfirmed()

        if (!confirmedCancelRepproval) return
        recreate = true
      } else if (updateAnticipationInfo?.paymentDateIsCloseToAnticipationDate) {
        setDialogMessage(
          'A data prevista de pagamento é menor que a próxima data prevista. Queres manter a data atual de pagamento?'
        )
        const confirmedCancelRepproval = await isConfirmed()

        if (!confirmedCancelRepproval) recreate = true
      }
    }

    handleUpdateAnticipation(buttonAction, recreate)
  }

  // const handleRemoveAnticipation = useCallback(async () => {
  //   setDialogMessage('Você tem certeza que deseja executar esta ação?')
  //   const confirmed = await isConfirmed()

  //   if (confirmed) {
  //     setIsLoading(true)
  //     await dispatch(deleteTradesmanAnticipation({ anticipationId: paymentID }))
  //       .then(() => {
  //         setIsLoading(false)
  //         closeModal()
  //       })
  //       .catch(() => setIsLoading(false))
  //   }
  // }, [isConfirmed, paymentOrder])

  const approvalButtons = useMemo(() => {
    const status = paymentOrder?.status

    if (status === 'approved' || status === 'paid' || paymentOrder?.datetimePayed) {
      return (
        <Grid className={styles.paidButtonsContainer}>
          <Button
            variant="ghost"
            className={styles.link}
            onClick={() => handleButtonClick('cancel_approval')}
            disabled={isLoading}
          >
            Remover aprovação
          </Button>
          <Button className={styles.link} variant="secondary" color="red" onClick={closeModal}>
            Fechar
          </Button>
        </Grid>
      )
    }
    if (status === 'reproved') {
      return (
        <Button
          variant="secondary"
          startIcon={<CheckIcon color={Theme.Colors.Primary.Base} />}
          onClick={() => handleButtonClick('cancel_reproval')}
          disabled={isLoading}
        >
          Cancelar reprovação
        </Button>
      )
    }

    return (
      <Grid className={styles.buttonsContainer}>
        {/* <Button
          variant="ghost"
          className={styles.link}
          onClick={handleRemoveAnticipation}
          disabled={isLoading}
        >
          Remover antecipação
        </Button> */}
        <Button
          variant="secondary"
          startIcon={<CheckIcon color={Theme.Colors.Primary.Base} />}
          onClick={() => handleButtonClick('approve')}
          disabled={isLoading}
        >
          Aprovar antecipação
        </Button>
        <Button
          variant="secondary"
          startIcon={<CloseIcon color={Theme.Colors.Red.Base} />}
          color="red"
          onClick={() => handleButtonClick('reprove')}
          disabled={isLoading}
        >
          Reprovar antecipação
        </Button>
      </Grid>
    )
  }, [paymentOrder, isLoading])

  useEffect(() => {
    getPayment()
  }, [paymentID])

  const anticipationPaymentDate = useMemo(
    () =>
      paymentOrder?.datetimePayed
        ? moment(paymentOrder?.datetimePayed).format('DD/MM/yyyy')
        : 'DD/MM/AAAA',
    [paymentOrder?.datetimePayed]
  )

  const showPaymentDate = useMemo(
    () => paymentOrder?.status === 'paid' || paymentOrder?.datetimePayed,
    [paymentOrder?.status]
  )

  return (
    <>
      <Dialog
        open
        maxWidth="xl"
        disableEscapeKeyDown
        onClose={closeModal}
        PaperComponent={PaperComponent}
        TransitionComponent={Transition}
      >
        <FormGroup>
          <DialogTitle className={styles.titleBlue} id="draggable-dialog-title">
            Antecipação de pagamento
          </DialogTitle>
          <Grid className={styles.wrapper}>
            <DialogContent className={styles.container}>
              <Grid className={styles.subcontainer}>
                <Grid className={styles.subcontainerWrapper}>
                  <Typography className={styles.title}>Data do pedido de antecipação</Typography>
                  <Typography>{formatDateTime(paymentOrder?.createdAt) || '-'}</Typography>
                </Grid>

                <Grid className={styles.subcontainerWrapper}>
                  <Typography className={styles.title}>Solicitante</Typography>
                  <Typography>{paymentOrder?.tradesmanName || '-'}</Typography>
                </Grid>
              </Grid>

              <Grid className={styles.subcontainer}>
                <Grid className={styles.subcontainerWrapper}>
                  <Typography className={styles.title}>Valor do prestador no orçamento</Typography>
                  <Typography>{formatter.format(paymentOrder?.providerPrice) || '-'}</Typography>
                </Grid>

                <Grid className={styles.subcontainerWrapper}>
                  <Typography className={styles.title}>Email da empresa</Typography>
                  <Typography>{paymentOrder?.companyEmail || '-'}</Typography>
                </Grid>
              </Grid>

              {showPaymentDate && (
                <Grid className={[styles.subcontainer, styles.subcontainerBorderTop]}>
                  <Grid className={styles.subcontainerWrapper}>
                    <Grid className={styles.titleWrapper}>
                      <Typography className={styles.title}>Data do pagamento</Typography>
                    </Grid>
                    <Typography>{anticipationPaymentDate}</Typography>
                  </Grid>
                </Grid>
              )}
            </DialogContent>
          </Grid>

          <DialogActions className={styles.dialogAction}>{approvalButtons}</DialogActions>
        </FormGroup>
      </Dialog>
      <ConfirmRemoveDialog message={dialogMessage} />
    </>
  )
}

PaymentAdvanceApproval.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default PaymentAdvanceApproval
